<template>
  <div>
    <app-page-edit page-title="Edit MJO" page-toolbar-title="Edit MJO">

      <template #page-toolbar-action>
        <v-btn :loading="loading" v-if="mjo_header.status == 'open'" text @click="saveData()">Save</v-btn>
      </template>

      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>
        <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Administration.MJO.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :loading="loading" v-if="mjo_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
        <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
      </template>

      <v-row v-if="(loading == false) && (typeof mjo_header.status !== 'undefined') && (mjo_header.status != 'open')">
        <v-col>
          <v-alert color="info" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="(notFound == true) && (typeof mjo_header.status === 'undefined')">
        <v-col>
          <v-alert color="warning" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="mjo_header.status == 'open'">
        <v-col cols="12" lg="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field dense disabled v-model="mjo_header.document_no_" placeholder="Doc. No." label="Doc. No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="mjo_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2" type="date"></v-text-field>
                <v-text-field dense disabled v-model="mjo_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-select dense v-model="mjo_header.company_id" :items="list_company_ids" item-text="label" item-value="value" placeholder="Company ID" label="Company ID" outlined hide-details class="mb-2"></v-select>
              </v-col>
              <v-col cols="6" md="6">
                <v-select :items="list_priorities" dense v-model="mjo_header.priority" placeholder="Prioritas" label="Prioritas" outlined hide-details class="mb-2"></v-select>
                <v-select :items="list_work_types" dense v-model="mjo_header.job_type" placeholder="Tipe Pekerjaan" label="Tipe Pekerjaan" outlined hide-details class="mb-2"></v-select>
                <app-date-time type="datetime-local" dense v-model="mjo_header.damage_at" placeholder="Tanggal Kerusakan" label="Tanggal Kerusakan" outlined hide-details class="mb-2"></app-date-time>
              </v-col>
            </v-row>
            <v-textarea dense v-model="mjo_header.problem_description" placeholder="Detil Masalah" label="Detil Masalah" outlined hide-details class="mb-2" height="100"></v-textarea>
            <v-textarea dense v-model="mjo_header.problem_description" placeholder="Detil Masalah" label="Detil Masalah" outlined hide-details class="mb-2" height="100"></v-textarea>
          </card-expansion>
          <card-expansion title="Fixed Asset" class="mb-3" :isMinimize="false">
            <template #action>
              <v-switch v-model="mjo_header.is_fixed_asset" label="Fixed Asset?" hide-details dense class="mt-1 mr-2"></v-switch>
            </template>
            <v-row v-if="mjo_header.is_fixed_asset">
              <v-col cols="12">
                <div class="mb-2">
                  <v-btn @click="showFindAsset()" small class="mb-2">
                    <v-icon>mdi-magnify</v-icon> Find Asset
                  </v-btn>
                  <v-btn @click="selected_fa = {}" small class="mb-2">Bersihkan</v-btn>
                </div>

                <div v-if="isEmpty(selected_fa.no_)">
                  <v-row>
                    <v-col>
                      <v-alert color="warning">Pilih asset terlebih dahulu.</v-alert>
                    </v-col>
                  </v-row>
                </div>

                <v-row v-if="!isEmpty(selected_fa.no_)">
                  <v-col cols="12" md="6">
                    <v-text-field label="FA No." v-model="selected_fa.no_" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Company ID" v-model="selected_fa.company_id" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Description" v-model="selected_fa.description" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Description 2" v-model="selected_fa.description2" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Area Code" v-model="selected_fa.area_code" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Location Code" v-model="selected_fa.location_code" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Responsibility" v-model="selected_fa.responsibility" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                    <v-text-field label="Serial No." v-model="selected_fa.serial_no_" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </card-expansion>
          <card-expansion title="Images" class="mb-3" :isMinimize="false">
            <template slot="action">
            </template>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field dense v-model="mjo_header.image1" placeholder="Image 1" label="Image 1" outlined hide-details class="mb-2" append-icon="mdi-upload" @click:append="uploadFile(1)"></v-text-field>
                <v-text-field dense v-model="mjo_header.image2" placeholder="Image 2" label="Image 2" outlined hide-details class="mb-2" append-icon="mdi-upload" @click:append="uploadFile(2)"></v-text-field>
                <v-text-field dense v-model="mjo_header.image3" placeholder="Image 3" label="Image 3" outlined hide-details class="mb-2" append-icon="mdi-upload" @click:append="uploadFile(3)"></v-text-field>
                <v-text-field dense v-model="mjo_header.image4" placeholder="Image 4" label="Image 4" outlined hide-details class="mb-2" append-icon="mdi-upload" @click:append="uploadFile(4)"></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :mjo_header="mjo_header"></document-info>
          <app-comment :document_id="parseInt(mjo_header.id)" :source_document="mjo_header.source_document" :document_no_="mjo_header.document_no_"></app-comment>
        </v-col>
      </v-row>

    </app-page-edit>

    <input type="file" style="display: none" accept="image/*" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />

    <FindFADialog ref="refFindFADialog" @callback="getSelectedItem"></FindFADialog>
  </div>
</template>

<script>
import FindFADialog from './components/FindFADialog.vue';
import DocumentInfo from './components/DocumentInfo.vue';

export default {
  components: {
    FindFADialog,
    DocumentInfo,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,
      modal1: false,
      modal2: false,
      tab: null,
      dialogDescAdd: false,
      dialogDescEdit: false,
      saveControl: false,
      search: '',

      list_company: [
        { label: 'SBU PRODUCTION', value: 'SBUP' },
        { label: 'SBU SALES', value: 'SBUS' },
      ],

      selected_fa: {},
      fa_loading: false,
      fa_list: [],
      mjo_header: {},
      selected_image: 0,

      loading_send_approval: false,

      status: false,

      list_priorities: [],
      list_work_types: [],
      list_company_ids: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    mjo_header(n, o) {
      this.saveControl = true
    },

    search(val) {
      // Items have already been loaded
      // if (this.fa_list.length > 0) return

      // Items have already been requested
      if (this.fa_loading) return

      this.fa_loading = true

      this.findFixedAsset(val)
    },


  },
  computed: {
    enableSendApprovalBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "send-approval") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getDetail(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "mjo/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.mjo_header = res.data.data.mjo_header;

          this.selected_fa.no_ = this.mjo_header.fa_no_;
          this.selected_fa.description = this.mjo_header.fa_description;
          this.selected_fa.description2 = this.mjo_header.fa_description2;
          this.selected_fa.fa_subclass_code = this.mjo_header.fa_fa_subclass_code;
          this.selected_fa.area_code = this.mjo_header.fa_area_code;
          this.selected_fa.location_code = this.mjo_header.fa_location_code;
          this.selected_fa.fa_location_code = this.mjo_header.fa_fa_location_code;
          this.selected_fa.responsibility = this.mjo_header.fa_responsibility;
          this.selected_fa.serial_no_ = this.mjo_header.fa_serial_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {

      if (!this.isEmpty(this.mjo_header.is_fixed_asset) && this.mjo_header.is_fixed_asset == true) {
        if (this.isEmpty(this.selected_fa.no_)) {
          this.showAlert("warning", "Select Fixed Asset");
          return;
        }
      }

      if (this.isEmpty(this.mjo_header.company_id)) {
        this.showAlert("warning", "Company ID is required");
        return;
      }

      if (this.isEmpty(this.mjo_header.damage_at)) {
        this.showAlert("warning", "Tanggal Kerusakan is required");
        return;
      }



      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();

      formData.append("mjo_header[id]", this.mjo_header.id);
      formData.append("mjo_header[company_id]", this.mjo_header.company_id);
      formData.append("mjo_header[description]", this.mjo_header.description);
      formData.append("mjo_header[problem_description]", this.mjo_header.problem_description);
      formData.append("mjo_header[is_fixed_asset]", this.mjo_header.is_fixed_asset);
      formData.append("mjo_header[priority]", this.mjo_header.priority);
      formData.append("mjo_header[job_type]", this.mjo_header.job_type);
      formData.append("mjo_header[damage_at]", this.mjo_header.damage_at);
      formData.append("mjo_header[fa_no_]", this.selected_fa.no_);

      formData.append("mjo_header[image1]", this.mjo_header.image1);
      formData.append("mjo_header[image2]", this.mjo_header.image2);
      formData.append("mjo_header[image3]", this.mjo_header.image3);
      formData.append("mjo_header[image4]", this.mjo_header.image4);

      await this.$axios
        .post("mjo/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            // this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    uploadFile(sa) {
      this.selected_image = sa;
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      if (this.selected_image == 1) {
        this.mjo_header.image1 = "";
      } else if (this.selected_image == 2) {
        this.mjo_header.image2 = "";
      } else if (this.selected_image == 3) {
        this.mjo_header.image3 = "";
      } else if (this.selected_image == 4) {
        this.mjo_header.image4 = "";
      }

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("mjo/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            if (this.selected_image == 1) {
              this.mjo_header.image1 = resData.data.file_src;
            } else if (this.selected_image == 2) {
              this.mjo_header.image2 = resData.data.file_src;
            } else if (this.selected_image == 3) {
              this.mjo_header.image3 = resData.data.file_src;
            } else if (this.selected_image == 4) {
              this.mjo_header.image4 = resData.data.file_src;
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    showFindAsset() {
      this.$refs.refFindFADialog.showDialog(this.mjo_header.company_id)
    },

    async findFixedAsset(search) {
      this.fa_loading = true;
      this.notFound = false;

      var config = {
        params: {
          search: search,
        },
      };
      await this.$axios
        .get("mjo/find-fixed-asset", config)
        .then((res) => {
          this.fa_list = res.data.data.results;

          this.fa_loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.fa_loading = false;
          this.axiosErrorHandler(error);
        });
    },

    getSelectedItem(selected_fa) {
      this.selected_fa = selected_fa
    },

    /**
     * sendApproval
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_send_approval = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/send-approval", formData)
          .then((res) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.goBack();
          })
          .catch((error) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },


    async getVars(refresh = false) {
      const uri = 'public/var'
      if (refresh == true)
        this.AxiosStorageRemove("GET", uri);
      await this.$axios
        .get(uri, {
          params: {
            name: [
              'mjo_priorities',
              'mjo_work_types',
              'company_id',
            ]
          }
        })
        .then((res) => {
          this.list_priorities = res.data.data.mjo_priorities;
          this.list_work_types = res.data.data.mjo_work_types;
          this.list_company_ids = res.data.data.company_id;
        })
    },
  },
  mounted() {
    this.id = this.$route.query.id;

    this.mjo_header.damage_at = new Date();

    this.getDetail();
    
    this.getVars();
  },
};
</script>
